import debug from '../modules/debug.js';

export default (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        debug.log(e);
        return false;
    }
    return true;
};
