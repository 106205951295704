import $ from 'jquery/dist/jquery.slim';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';

import isJsonString from '../utils/isJsonString';
import {siteLanguage} from '../utils/language';

import debug from './debug.js';
import {TrackingForms} from './tracking-forms';

export default (formId) => {
    onlineIntensiveCoursesFormAbstract(formId);
};

export function onlineIntensiveCoursesFormAbstract(formId) {
     
    const $registerFormIntensiveTemporary = $(`form[id^="${formId}"]`);
    const $registerFormIntensiveId = $registerFormIntensiveTemporary.attr('id');
    const $registerFormIntensive = $(`form[id="${$registerFormIntensiveId}"]`);

    if ($registerFormIntensive.length > 0) {
        const init = () => {
            checkSpecificPriceJsonValidity(); // Should be called first

            initCourseDurationSelect();
            setRequiredProvinceShort();
            setCustomerId();
            formValidation();
        };

        const $countryField = $('[id$="-country"]', $registerFormIntensive);
        const $countrySpecialField = $('*[data-show-country]');
        const $provinceField = $('[id$="-province"]',
            $registerFormIntensive);
        const $provinceShortField = $('[id$="-provinceShort"]',
            $registerFormIntensive);
        const $durationField = $('[id$="-courseDuration"]',
            $registerFormIntensive);
        const $emailField = $('[id$="-email"]', $registerFormIntensive);
        const $emailRepeatField = $('[id$="-emailRepeat"]',
            $registerFormIntensive);
        const $orderOverview = $('.order-overview', $registerFormIntensive);
        const $summaryText = $('.form-element-summary-text',
            $registerFormIntensive);

        const $specificPrices = $('.specific-prices', $registerFormIntensive);
        const $defaultPrice = $specificPrices.data('default-price');

        const $orderOverviewDurationValue = $('.order-overview__duration-value',
            $registerFormIntensive);
        const $orderOverviewCoursePrice = $('.order-overview__course-price',
            $registerFormIntensive);

        const $summaryDurationValue = $('.summary-duration-value',
            $registerFormIntensive);
        const $summaryDurationLabel = $('.summary-duration-label',
            $registerFormIntensive);
        const $summaryDurationLabelSingular = $summaryDurationLabel.data(
            'duration-label-singular');
        const $summaryDurationLabelPlural = $summaryDurationLabel.data(
            'duration-label-plural');
        const $summaryPrice = $('.summary-price', $registerFormIntensive);

        const $monthlyPriceHiddenField = $('[id$="-monthlyPrice"]',
            $registerFormIntensive);
        const $coursePriceHiddenField = $('[id$="-coursePrice"]',
            $registerFormIntensive);
        const $courseDurationUidHiddenField = $('[id$="-courseDurationUid"]',
            $registerFormIntensive);
        const $customerIdField = $('[id$="-customerId"]',
            $registerFormIntensive);

        const $submitButton = $('[type="submit"]', $registerFormIntensive);

        const isHidden = 'is-hidden';

        const trackingContactForm = new TrackingForms();

        let specificPriceJsonValidityState = 'true';

        const checkSpecificPriceJsonValidity = () => {
            if (!isJsonString($specificPrices.text())) {
                debug.log('[ERROR] JSON for specific price is invalid!');
                specificPriceJsonValidityState = 'false';
            } else {
                specificPriceJsonValidityState = 'true';
            }
        };

        const initCourseDurationSelect = () => {
            if (specificPriceJsonValidityState === 'true') {
                // Prepare select field
                const $specificPricesJson = JSON.parse($specificPrices.text());
                _forEach($specificPricesJson.labels, ($label, $index) => {
                    $durationField.append(
                        `<option value="${$index}">${$label}</option>`);
                });
            }
        };

        const showOrderOverview = () => {
            $orderOverview.removeClass(isHidden);
        };

        const hideOrderOverview = () => {
            $orderOverview.addClass(isHidden);
        };

        const showSummary = () => {
            $summaryText.removeClass(isHidden);
        };

        const hideSummary = () => {
            $summaryText.addClass(isHidden);
        };

        const calculatePrice = (duration) => {
            const $priceForSpecificMonth = Number.parseFloat(
                $specificPrices.attr(
                    'data-price-month-' + duration).replace(/,/g, '.'));

            const price = new Intl.NumberFormat(siteLanguage,
                {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(duration * $priceForSpecificMonth);

            // format we need for API: 1999.78
            // (nothing between hundreds and thousands)
            const priceEnglish = new Intl.NumberFormat('en-US',
                {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(duration * $priceForSpecificMonth);
            const decimalString = priceEnglish.replace(/,/g, '');

            $orderOverviewCoursePrice.text(price);
            $summaryPrice.text(price);

            $coursePriceHiddenField.val(decimalString);
            $monthlyPriceHiddenField.val($priceForSpecificMonth);
        };

        const resetDurationField = () => {
            $durationField.val('');
        };

        const setCountryForSpecificPrice = (country) => {
            $specificPrices.attr('data-current-country', country);
        };

        const setSpecificPricesFromCountry = (country) => {
            const $specificPricesJson = JSON.parse($specificPrices.text());

            const $specificPriceForCountry = Object.values(
                _pick($specificPricesJson.prices,
                    country));

            const setDataAttributeForDefaultPrice = () => {
                const durations = Object.keys(JSON.parse($specificPrices.text()).labels);
                for (const index of durations) {
                    $specificPrices.attr('data-price-month-' + index,
                        $defaultPrice);
                }
            };

            const setDataAttributeForSpecificPrice = () => {
                const durations = Object.keys(JSON.parse($specificPrices.text()).labels);
                for (const index of durations) {
                    const $specificPrice = $specificPriceForCountry[0][index];

                    $specificPrices.attr('data-price-month-' + index,
                        $specificPrice);
                }
            };

            if (_isEmpty($specificPriceForCountry)) {
                setDataAttributeForDefaultPrice();
            } else {
                setDataAttributeForSpecificPrice();
            }
        };

        const setOrderOverviewDurationValue = (duration) => {
            $orderOverviewDurationValue.text(duration);
        };

        const setSummaryDurationValue = (duration) => {
            $summaryDurationValue.text(duration);
        };

        const setSummaryDurationLabel = (duration) => {
            if (duration === '1') {
                $summaryDurationLabel.text($summaryDurationLabelSingular);
            } else {
                $summaryDurationLabel.text($summaryDurationLabelPlural);
            }
        };

        const checkProvinceShortField = () => {
            if ($provinceField.val() !== '') {
                $provinceShortField.prop('required', true);
            } else {
                $provinceShortField.prop('required', false);
            }
        };

        const setRequiredProvinceShort = () => {
            // Add required asterisk
            $provinceShortField.parent().find('.form-label').append('<span class="required">**</span>');

            // Set required attribute for 'province short' if 'province' field is filled
            $provinceField.on('blur', () => {
                checkProvinceShortField();
            });
        };

        const showCountrySpecialFields = () => {
            const $countryValue = $countryField.val();

            $countrySpecialField.each((index, element) => {
                const $this = $(element);
                const $country = $this.data('show-country');

                $country.split(',').map((countryCode) => {
                    if (countryCode.includes($countryValue)) {
                        // Show special country field
                        $this.closest('.form-element-gridrow-column').removeClass(isHidden);
                    }
                });
            });
        };

        const resetCountrySpecialFields = () => {
            const $specialField = $('*[data-show-country]');

            $specialField.each((index, element) => {
                const $this = $(element);

                $this.closest('.form-element-gridrow-column').addClass(isHidden);
                $this.prop('required', false);
            });
        };

        const setCustomerId = () => {
            const $locationId = $customerIdField.val();
            trackingContactForm.applyCustomerId($locationId);
        };

        $countryField.on('change', () => {
            const $country = $countryField.val();

            resetDurationField();
            hideOrderOverview();
            hideSummary();
            resetCountrySpecialFields();
            showCountrySpecialFields();
            setCountryForSpecificPrice($country);

            if (specificPriceJsonValidityState === 'true') {
                setSpecificPricesFromCountry($country);
            }
        });

        $durationField.on('change', () => {
            const $country = $countryField.val();
            const $duration = $durationField.val();

            // Show order overview and summary if country and duration fields are selected
            if ($country && $duration && specificPriceJsonValidityState ===
                'true') {
                calculatePrice($duration);
                setDurationUid($duration);
                setOrderOverviewDurationValue($duration);
                showOrderOverview();
                setSummaryDurationValue($duration);
                setSummaryDurationLabel($duration);
                showSummary();
            }
        });

        const setDurationUid = (duration) => {
            const durations = JSON.parse($specificPrices.text()).durationUids;

            $courseDurationUidHiddenField.val(durations[duration]);
        };

        const formValidation = () => {
            const isDisabled = 'is-disabled';
            const isInvalid = 'is-invalid';

            let validStateForm = 'false';
            let validateStatePrivacy = 'false';
            let validateEmailFields = 'false';
            let validateStateTerms = 'false';

            // Check specific price JSON validity -> prevent submitting form
            if (specificPriceJsonValidityState === 'false') {
                validStateForm = 'false';
                $registerFormIntensive.addClass(isDisabled);
            } else {
                validStateForm = 'true';
            }

            // Show error (only red border and symbol)
            const showError = ($field) => {
                $field.addClass(isInvalid);
            };

            // Show error message below invalidated field
            const showCompareErrorMessage = ($field) => {
                const $errorMessage = $field.data('validator-compare-error');
                const $errorMessageContainer = $field.parent().find('.invalid-feedback');

                if ($errorMessageContainer.length === 0) {
                    $field.after(
                        `<div class="invalid-feedback">${$errorMessage}</div>`);
                    showError($field);
                }
            };

            // Remove error message
            const resetError = ($field) => {
                const $errorMessageContainer = $field.parent().find('.invalid-feedback');

                $errorMessageContainer.remove();
                $field.removeClass(isInvalid);
            };

            // Compare email fields
            const compareEmailFields = () => {
                if ($emailField.val() !== '' && $emailRepeatField.val() !==
                    '') {
                    if ($emailField.val() === $emailRepeatField.val()) {
                        resetError($emailField);
                        resetError($emailRepeatField);
                        validateEmailFields = 'true';
                    } else {
                        showError($emailField);
                        showCompareErrorMessage($emailRepeatField);
                        validateEmailFields = 'false';
                    }
                } else {
                    resetError($emailField);
                    resetError($emailRepeatField);
                    validateEmailFields = 'true';
                }
            };

            // Check email fields on typing
            $emailField.on('input', compareEmailFields);
            $emailRepeatField.on('input', compareEmailFields);

            const checkPrivacy = () => {
                const $privacyField = $(
                    '[type="checkbox"][data-identifier="privacy"]',
                    $registerFormIntensive);

                const $isChecked = $privacyField.prop('checked');

                if ($isChecked) {
                    validateStatePrivacy = 'true';
                    resetError($privacyField);
                } else {
                    validateStatePrivacy = 'false';
                    showError($privacyField);
                }
            };

            const checkTerms = () => {
                const $termsField = $(
                    '[type="checkbox"][data-identifier="terms"]',
                    $registerFormIntensive);

                const $isChecked = $termsField.prop('checked');

                if ($isChecked) {
                    validateStateTerms = 'true';
                    resetError($termsField);
                } else {
                    validateStateTerms = 'false';
                    showError($termsField);
                }
            };

            const checkRequiredFields = () => {
                const $requiredFields = $('input:required',
                    $registerFormIntensive);

                $requiredFields.each((index, element) => {
                    const $this = $(element);
                    const $errorMessageContainer = $this.parent().find('.invalid-feedback');

                    if ($errorMessageContainer.length === 0) {
                        if (!$this.val()) {
                            showError($this);
                        } else {
                            resetError($this);
                        }
                    }
                });
            };

            const checkIfFormIsValid = () => {
                checkProvinceShortField();
                compareEmailFields();
                checkPrivacy();
                checkTerms();
                checkRequiredFields();

                if (
                    validateEmailFields === 'false' ||
                    validateStatePrivacy === 'false' ||
                    validateStateTerms === 'false'
                ) {
                    validStateForm = 'false';
                } else {
                    validStateForm = 'true';
                }
            };

            $submitButton.on('click', () => {
                checkIfFormIsValid();

                if (validStateForm === 'false') {
                    event.preventDefault();
                }
            });
        };

        init();
    }
     
}
