import {electronicFormatIBAN, isValidIBAN} from 'ibantools';
import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let registerScrollToSelectedPaymentMethod;
    let registerIbanValidation;
    let registerTosValidation;

    const $paymentOptionForm = $('#changePaymentForm');
    const $paymentConfirmForm = $('#confirmOrderForm');
    const $ibanInputFields = $('#novalnetsepaAccountData, #novalnetsepainstalmentAccountData');
    const $submitButton = $('#confirmFormSubmit');
    const $tosCheckbox = $('.checkout-confirm-tos-checkbox');

    init = () => {
        registerScrollToSelectedPaymentMethod();
        registerIbanValidation();
        registerTosValidation();
    };

    registerScrollToSelectedPaymentMethod = () => {
        if ($paymentOptionForm.length > 0 && window.location.search === '?redirected=0') {
            window.location.hash = 'changePaymentForm';
        }
    };

    registerTosValidation = () => {
        $tosCheckbox.on('change', function() {
            const $singleCheckbox = $(this);
            $singleCheckbox.parent().children('label').removeClass('tos-invalid');
        });

        $tosCheckbox.on('invalid', function() {
            $tosCheckbox.each(function() {
                const $singleCheckbox = $(this);
                if (!$singleCheckbox.is(':invalid')) {
                    $singleCheckbox.parent().children('label').removeClass('tos-invalid');
                }
            });

            const $singleCheckbox = $(this);
            if ($singleCheckbox.is(':invalid')) {
                $singleCheckbox.parent().children('label').addClass('tos-invalid');
            }
        });
    };

    registerIbanValidation = () => {
        $ibanInputFields.each(function() {
            $(this).on('keydown', function() {
                $(this).removeClass('invalid');
            });
        });

        $paymentConfirmForm.on('submit', function(event) {
            $ibanInputFields.each(function() {
                const $ibanInputField = $(this);
                if ($ibanInputField.is(':visible')) {
                    const iban = electronicFormatIBAN($ibanInputField.val());
                    if (isValidIBAN(iban) === false) {
                        event.preventDefault(); window.location.hash = 'changePaymentForm';
                        $ibanInputField.addClass('invalid').focus();

                        setTimeout(() => {
                            $submitButton.removeAttr('disabled');
                            $('.loader', $submitButton).remove();
                        }, 250);
                    }
                }
            });
        });
    };

    init();
};
