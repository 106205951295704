import BootstrapModal from 'bootstrap/js/dist/modal';
import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let abortModal;

    init = () => {
        abortModal();
    };

    abortModal = () => {
        const $checkoutPage = $('body.checkout').
            not('body.checkout--finish-page, body.checkout--home-page');
        const $abortModal = $('#iql-abort-modal');

        let bootstrapAbortModal;

        if ($abortModal.length > 0) {
            bootstrapAbortModal = new BootstrapModal(
                document.getElementById('iql-abort-modal'));
        }

        const $abortRedirectUrlField = $(
            '#abort-redirect-url', $checkoutPage);

        if ($checkoutPage.length > 0) {
            const $footerMetaNavigation = $('.page-footer', $checkoutPage);
            const $footerMetaNavigationLinks = $('a', $footerMetaNavigation);
            const $pageMainHeader = $('.page-header__main', $checkoutPage);
            const $pageMainHeaderLinks = $('a', $pageMainHeader);

            let $abortElements = $footerMetaNavigationLinks.add(
                $pageMainHeaderLinks);

            // Abort modal handling
            $abortElements.on('click', (event) => {
                const $this = $(event.currentTarget);
                const $href = $this.attr('href');

                // Add 'href' from clicked link into modal abort button
                $abortRedirectUrlField.attr('value', $href);
                // Show abort modal
                bootstrapAbortModal.show();

                // Prevent abort checkout
                event.preventDefault();
            });
        }
    };

    init();
};
