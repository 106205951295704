import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let emptyImageSrcAttribute;

    const noSrc = 'no-src';

    init = () => {
        emptyImageSrcAttribute();
    };

    emptyImageSrcAttribute = () => {
        const $image = $('img');

        if ($image.length > 0) {
            $image.each((index, element) => {
                const $this = $(element);
                const $imageSrcAttribute = $this.attr('src');

                if ($imageSrcAttribute === '') {
                    $this.addClass(noSrc);
                    $this.attr('data-no-source-attribute', 'Please add a source!');
                }
            });
        }
    };

    init();
};
