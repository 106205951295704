import $ from 'jquery/dist/jquery.slim';

import debug from './debug.js';
import {TrackingForms} from './tracking-forms';

export default ($validationForms, options) => {
    $validationForms.each((index) => {
        const $validationForm = $($validationForms[index]);
        const $locationField = $('[id$="-location"]', $validationForm);
        const $privacyField = $('[id$="-privacy"]', $validationForm);
        const $privacyFormElement = $privacyField.closest('.form-element');
        const $privacyFranchiseField = $('[id$="-privacyFranchise"]',
            $validationForm);
        const $privacyFranchiseFormElement = $privacyFranchiseField.closest(
            '.form-element');
        const $submitButton = $('[type="submit"]', $validationForm);
        const isDisabled = 'is-disabled';
        const isHidden = 'is-hidden';
        const isSelectedPrivacy = 'is-selected-privacy';
        const $privacyFranchiseLink = $('.js-privacy-franchise-link',
            $validationForm);
        const $privacyFranchiseHref = $privacyFranchiseLink.attr('href');
        const trackingContactForm = new TrackingForms();

        const init = () => {
            checkNumberOfLocations();
        };

        const setCustomerId = (locationId) => {
            trackingContactForm.applyCustomerId(locationId);
        };

        const setFranchisePartnerParameter = (locationType, locationId) => {
            // If franchise partner
            if (locationType === 1) {
                $privacyFranchiseLink.attr('href',
                    $privacyFranchiseHref + '?franchiseIdentifier=' +
                    locationId);
            }
        };

        const togglePrivacyFormElement = (locationType) => {
            if (locationType === 0) {
                // Is not a franchise partner
                $privacyFormElement.removeClass(isHidden);
                $privacyFormElement.removeClass(isDisabled);
                $privacyField.attr('disabled', false);
                $privacyFranchiseFormElement.addClass(isHidden);
                $privacyFormElement.addClass(isSelectedPrivacy);
                $privacyFranchiseFormElement.removeClass(isSelectedPrivacy);
            } else {
                // Is a franchise partner
                $privacyFormElement.addClass(isHidden);
                $privacyFranchiseFormElement.removeClass(isHidden);
                $privacyFormElement.removeClass(isSelectedPrivacy);
                $privacyFranchiseFormElement.addClass(isSelectedPrivacy);
            }
        };

        const checkNumberOfLocations = () => {
            const $locations = $('[value!=""][value]', $locationField); // Options with value
            const $locationCount = $locations.length;

            let $locationId;
            let $locationType;

            const hideLocationFormElement = () => {
                const $locationFormElement = $locationField.closest(
                    '.form-element');
                $locationFormElement.hide();
            };

            const showPrivacyFormElement = () => {
                $locationId = $locations.val();
                $locationType = $locations.data('franchise');

                $locations.prop('selected', true);

                togglePrivacyFormElement($locationType);
            };

            // Check number of locations
            if ($locationCount === 1) {
                // One location
                hideLocationFormElement();
                showPrivacyFormElement();
                setCustomerId($locationId);
                setFranchisePartnerParameter($locationType, $locationId);
            } else if ($locationCount < 1) {
                // Throw error if no locations available
                debug.log('No locations available!');
            } else {
                // Multiple location
                initTogglePrivacyFormElement();
            }
            initFormValidation();
        };

        const initTogglePrivacyFormElement = () => {
            $locationField.on('change', (event) => {
                const $this = $(event.currentTarget);
                const $selectedOption = $this.children('option:selected');
                const $selectedOptionValue = $selectedOption.val();
                const $locationType = $selectedOption.data('franchise');

                // Reset checkboxes
                $privacyField.prop('checked', false);
                $privacyFranchiseField.prop('checked', false);

                togglePrivacyFormElement($locationType);
                setFranchisePartnerParameter($locationType,
                    $selectedOptionValue);
                setCustomerId($selectedOptionValue);
            });
        };

        const initFormValidation = () => {
            const isInvalid = 'is-invalid';

            let validStateForm = false;
            let validateStatePrivacy = false;

            // Show error (only red border and symbol)
            const showError = ($field) => {
                $field.addClass(isInvalid);
            };

            // Remove error message
            const resetError = ($field) => {
                const $errorMessageContainer = $field.parent().find('.invalid-feedback');

                $errorMessageContainer.remove();
                $field.removeClass(isInvalid);
            };

            // Check privacy (privacy checkbox should be checked)
            const checkPrivacy = () => {
                const $selectedPrivacyFields = $('[name*="[privacy]"][type="checkbox"], [name*="[privacyFranchise]"][type="checkbox"], [name*="[terms]"][type="checkbox"]',
                    $validationForm);
                validateStatePrivacy = true;
                if ($selectedPrivacyFields.length > 0) {
                    $selectedPrivacyFields.each((index) => {
                        if (!$($selectedPrivacyFields[index]).prop('checked') &&
                            (!options.ignoreDisabled || (!$($selectedPrivacyFields[index]).is(':disabled') &&
                                !$($selectedPrivacyFields[index]).parent().is(':hidden')))) {
                            validateStatePrivacy = false;
                        }
                    });
                }
            };

            const checkRequiredFields = () => {
                const $requiredFields = $('input:required, select:required',
                    $validationForm);

                validStateForm = true;
                $requiredFields.each(function() {
                    if (!$(this).val() && (!options.ignoreDisabled || !$(this).is(':disabled'))) {
                        showError($(this));
                        validStateForm = false;
                    } else {
                        resetError($((this)));
                    }
                });
            };

            const checkIfFormIsValid = () => {
                checkPrivacy();
                checkRequiredFields();
            };

            $validationForm.on('submit', (event) => {
                checkIfFormIsValid();
                if (!(validateStatePrivacy && validStateForm)) {
                    event.preventDefault();
                } else {
                    $submitButton.addClass('active-animation');

                    if ($submitButton.attr('name')) {
                        // we need the current page field which is on the submit button. copy all data to a hidden field
                        const $hiddenFormField = $('<input type="hidden" value="' +
                            $submitButton.attr('value') + '" name="' + $submitButton.attr('name') + '"/>');
                        $submitButton.removeAttr('name').removeAttr('value');
                        $validationForm.append($hiddenFormField);
                    }

                    $submitButton.attr('disabled', 'disabled');

                    setTimeout(() => {
                        // on error, page won't load. re-enable button and remove animation
                        $submitButton.removeClass('finished-animation')
                        .removeClass('active-animation')
                        .removeAttr('disabled');
                    }, 5000);

                    $submitButton.on('transitionend', (transitionEvent) => {
                        if ($submitButton.hasClass('active-animation') &&
                            transitionEvent.originalEvent.propertyName === 'width') {
                            $submitButton.removeClass('active-animation')
                            .addClass('finished-animation');
                        }
                    });
                }
            });
        };

        init();
    });
};
