// https://dev.to/munawwar/lodash-chaining-revisited-1c5d
export default function chain(value) {
    return {
        /**
         * @param {function|string} func function or function name (in chained value)
         * @param  {...any} args
         */
        fn(func, ...args) {
            if (typeof func === 'string') {
                return chain(value[func](...args));
            }
            return chain(func(value, ...args));
        },
        value: () => value,
    };
}
