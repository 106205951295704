import Popover from 'bootstrap/js/dist/popover';
import $ from 'jquery/dist/jquery.slim';

const initLocationsMap = () => {
    $('[data-bs-toggle="popover"]').each(function() {
        new Popover(this, {html: true});
    });

    $('li.locations-list__item').hover(function () {
        if ($('.locations-list__map').is(':visible')) {
            Popover
                .getInstance('#locationPopupId' + $(this).data('location-id'))
                .toggle();
        }
    });
};

export default initLocationsMap;
