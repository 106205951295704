import Splide from '@splidejs/splide';
import $ from 'jquery/dist/jquery.slim';

import {viewportSize} from '../utils/viewport';

export default () => {
    let init;
    let SliderDefaults;
    let sliderGoogleReviews;
    let sliderLocation;
    let sliderLecturers;
    let sliderImageGallery;
    let sliderOfferings;
    let sliderTextBox;

    init = () => {
        SliderDefaults();
        sliderGoogleReviews();
        sliderLocation();
        sliderLecturers();
        sliderImageGallery();
        sliderOfferings();
        sliderTextBox();
    };

    SliderDefaults = () => {
        Splide.defaults = {
            classes: {
                // We reverse the direction
next: 'splide__arrow--prev',
                
page: 'splide__pagination-page', 
                prev: 'splide__arrow--next', // We reverse the direction
            },
            padding: '12%',
            pagination: false,
        };
    };

    sliderGoogleReviews = () => {
        const $sliderElement = $('.slider--google-reviews .splide');

        $sliderElement.each((index, element) => {
            const that = $(element).get(0); // Native DOM element for splide

            const slider = new Splide(that, {
                breakpoints: {
                    991: {
                        arrows: false,
                        pagination: true,
                        perPage: 1,
                    },
                },
                perPage: 2,
            });

            slider.mount();
        });
    };

    sliderLocation = () => {
        const $sliderLocationElement = $('.slider--blue-cards .splide');

        $sliderLocationElement.each((index, element) => {
            const that = $(element).get(0);
            const slider = new Splide(that, {
                breakpoints: {
                    991: {
                        arrows: false,
                        pagination: true,
                        perPage: 1,
                    },
                },
                perPage: 3,
            });

            slider.mount();
        });
    };

    sliderLecturers = () => {
        const $sliderElement = $('.slider--lecturers .splide');

        const centreImages = ($context) => {
            $context.addClass('justify-content-center');
        };

        if ($sliderElement.length > 0) {
            $sliderElement.each((index, element) => {
                const $this = $(element);
                const that = $(element).get(0); // Native DOM element for splide
                const $splideList = $('.splide__list', $this);
                const $splideCount = $('.splide__slide', $this).length;

                let dragStatus = true;

                viewportSize.subscribe(size => {
                    if (size === 'xs') {
                        // Max. 1 image
                        if ($splideCount === 1) {
                            dragStatus = false;
                        }
                    } else if (size === 'sm') {
                        // Max. 2 image
                        if ($splideCount < 3) {
                            dragStatus = false;
                            centreImages($splideList);
                        }
                    } else if (size === 'md' || size === 'lg') {
                        // Max. 3 image
                        if ($splideCount < 4) {
                            dragStatus = false;
                            centreImages($splideList);
                        }
                    } else if (size === 'xl' || size === 'xxl') {
                        // Max. 4 image
                        if ($splideCount < 5) {
                            dragStatus = false;
                            centreImages($splideList);
                        }
                    }
                });

                const slider = new Splide(that, {
                    breakpoints: {
                        1199: {
                            arrows: false,
                            pagination: true,
                            perPage: 3,
                        },
                        575: {
                            perPage: 1,
                        },
                        767: {
                            perPage: 2,
                        },
                    },
                    drag: dragStatus,
                    perPage: 4,
                });

                slider.mount();
            });
        }
    };

    sliderImageGallery = () => {
        const $sliderElement = $('.frame-type-image .splide');

        const centreImages = ($context) => {
            $context.addClass('justify-content-center');
        };

        if ($sliderElement.length > 0) {
            $sliderElement.each((index, element) => {
                const $this = $(element);
                const that = $(element).get(0); // Native DOM element for splide
                const $splideList = $('.splide__list', $this);
                const $splideCount = $('.splide__slide', $this).length;

                let customDrag = true;
                let customPerPage = 2;

                viewportSize.subscribe(size => {
                    if (size === 'xs' || size === 'sm') {
                        // Max. 1 image
                        if ($splideCount === 1) {
                            customDrag = false;
                        }
                    } else if (size === 'md' || size === 'lg' || size ===
                        'xl' || size === 'xxl') {
                        // Max. 2 image
                        if ($splideCount < 3) {
                            customDrag = false;
                            centreImages($splideList);
                        }

                        // Resize image if exist only one image
                        if ($splideCount === 1) {
                            customPerPage = 1;
                        }
                    }
                });

                const slider = new Splide(that, {
                    breakpoints: {
                        1399: {
                            arrows: false,
                            pagination: true,
                            perPage: customPerPage,
                        },
                        767: {
                            perPage: 1,
                        },
                    },
                    drag: customDrag,
                    perPage: 2,
                });

                slider.mount();
            });
        }
    };

    sliderOfferings = () => {
        const $sliderElement = $('.slider--offerings .splide');

        if ($sliderElement.length > 0) {
            $sliderElement.each((index, element) => {
                const $that = $(element);
                const that = $that.get(0); // Native DOM element for splide
                const slider = new Splide(that, {
                    breakpoints: {
                        767: {
                            gap: '0.5rem',
                            perPage: 1,
                            start: ($that.data('highlighted') - 1) || 0,
                        },
                        991: {
                            arrows: false,
                            drag: true,
                            gap: '1rem',
                            padding: '12%',
                            pagination: true,
                            perPage: 2,
                        },
                    },
                    drag: false,
                    gap: '2rem',
                    padding: '0',
                    perPage: 3,
                });

                slider.mount();
            });
        }
    };

    sliderTextBox = () => {
        const $sliderElement = $('.slider--text-box .splide');

        if ($sliderElement.length > 0) {
            $sliderElement.each((index, element) => {
                const $this = $(element);
                const that = $(element).get(0); // Native DOM element for splide
                const $sliderSlide = $('.splide__slide', $this);

                viewportSize.subscribe(size => {
                    if (size === 'lg') {
                        // Remove unnecessary width style from smaller viewports
                        $sliderSlide.attr('style', null);
                    }
                });

                const slider = new Splide(that, {
                    autoWidth: true,
                    breakpoints: {
                        991: {
                            arrows: false,
                            autoWidth: false,
                            drag: true,
                            padding: '12%',
                            pagination: true,
                            perPage: 1,
                        },
                    },
                    drag: false,
                    padding: '0',
                    perPage: 2,
                });

                slider.mount();
            });
        }
    };

    init();
};
