import $ from 'jquery/dist/jquery.slim';

export default function showUsercentricsSettings() {
    const $showUsercentricsSettingsTrigger = $(
        '.js-show-usercentrics-settings');

    if ($showUsercentricsSettingsTrigger.length > 0) {
        $showUsercentricsSettingsTrigger.on('click', (event) => {
            /** @var {*} UC_UI */
            /** @var {*} __ucCmp */
            /** @var {*} showSecondLayer */
            UC_UI.showSecondLayer();
            event.preventDefault();
        });
    }
};
