import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let initGoogleReviewsStarbar;

    init = () => {
        initGoogleReviewsStarbar();
    };

    initGoogleReviewsStarbar = () => {
        const $starbar = $('.google-reviews__starbar');

        if ($starbar.length > 0) {
            $starbar.each((index, element) => {
                const $this = $(element);
                const $icon = $('.icon', $this);

                let $starCount = $this.attr('data-iql-stars');

                if ($starCount > 5) {
$starCount = 5;
}

                $icon.slice(0, $starCount).addClass('is-filled');
            });
        }
    };

    init();
};
