// Globals
import language from './packages/pt_iql_base/Build/src/assets/javascript/utils/language';
import viewport from './packages/pt_iql_base/Build/src/assets/javascript/utils/viewport';
import buttonGroup from './packages/pt_iql_base/Build/src/assets/javascript/modules/button-group';
import checkout from './packages/pt_iql_base/Build/src/assets/javascript/modules/checkout';
import checkoutAbortModal from './packages/pt_iql_base/Build/src/assets/javascript/modules/checkout-abort-modal';
import checkoutCloseModal from './packages/pt_iql_base/Build/src/assets/javascript/modules/checkout-close-modal';
import checkoutConfirmationPage
    from './packages/pt_iql_base/Build/src/assets/javascript/modules/checkout-confirmation-page';
import consultationForm from './packages/pt_iql_base/Build/src/assets/javascript/modules/consultation-form';
import validationForm from './packages/pt_iql_base/Build/src/assets/javascript/modules/validation-form';
import cookieConsent from './packages/pt_iql_base/Build/src/assets/javascript/modules/cookie-consent';
import googleReviewsStarbar from './packages/pt_iql_base/Build/src/assets/javascript/modules/google-reviews-starbar';
import {
    internationalPhoneNumber
} from './packages/pt_iql_base/Build/src/assets/javascript/modules/international-phone-number';
import empty from './packages/pt_iql_base/Build/src/assets/javascript/modules/empty';
import onlineIntensiveGermanForYouthsForm
    from './packages/pt_iql_base/Build/src/assets/javascript/modules/online-intensive-german-for-youths-form';
import languageSelector from './packages/pt_iql_base/Build/src/assets/javascript/modules/language-selector';
import languageSelectShop from './packages/pt_iql_base/Build/src/assets/javascript/modules/language-select-shop';
import locationsMap from './packages/pt_iql_base/Build/src/assets/javascript/modules/locations-map';
import mainNavigation from './packages/pt_iql_base/Build/src/assets/javascript/modules/main-navigation';
import onlineIntensiveCoursesForm
    from './packages/pt_iql_base/Build/src/assets/javascript/modules/online-intensive-courses-form';
import scrollToTop from './packages/pt_iql_base/Build/src/assets/javascript/modules/scroll-to-top';
import showUsercentricsSettings from './packages/pt_iql_base/Build/src/assets/javascript/modules/usercentrics';
import slider from './packages/pt_iql_base/Build/src/assets/javascript/modules/slider';
import sticky from './packages/pt_iql_base/Build/src/assets/javascript/modules/sticky';
import trackingForms from './packages/pt_iql_base/Build/src/assets/javascript/modules/tracking-forms';
import youtube from './packages/pt_iql_base/Build/src/assets/javascript/modules/youtube';

import $ from 'jquery/dist/jquery.slim';

import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';

import './packages/pt_iql_base/Build/src/assets/styles/app.scss';
import './packages/pt_iql_base/Build/src/assets/styles/backend.scss';
import './packages/pt_iql_base/Build/src/assets/styles/rte.scss';
import './packages/pt_iql_base/Build/src/assets/styles/rte-interface.scss';

if (!window.jQuery) {
    window.jQuery = $;
}
if (!window.$) {
    window.$ = $;
}

// Utils
const utils = () => {
    language();
    viewport();
};

// Components
const components = () => {
    buttonGroup();
    checkout();
    checkoutAbortModal();
    checkoutCloseModal();
    checkoutConfirmationPage();
    consultationForm();
    validationForm($('form[id^="form_contact"], ' +
        'form[id^="form_onlinecourse"], ' +
        'form[id^="form_consultation"], ' +
        'form[id^="form_germanforyouths"], ' +
        'form[id^="episerver-form"]'), {ignoreDisabled: true});
    cookieConsent();
    empty();
    onlineIntensiveGermanForYouthsForm();
    googleReviewsStarbar();
    internationalPhoneNumber();
    languageSelector();
    languageSelectShop();
    locationsMap();
    mainNavigation();
    onlineIntensiveCoursesForm();
    scrollToTop();
    showUsercentricsSettings();
    slider();
    sticky();
    trackingForms();
    youtube();
};

// Utils should be loaded first
utils();

// Components
components();
