import $ from 'jquery/dist/jquery.slim';

// TODO: Refactor language selector -> class LanguageSelector

export default () => {
    let init;
    let languageSelectorLarge;
    let languageSelectorSmall;

    const isFoldableClass = 'is-foldable';
    const isOpen = 'is-open';
    const isVisibleClass = 'is-visible';

    init = () => {
        languageSelectorLarge();
        languageSelectorSmall();
    };

    languageSelectorLarge = () => {
        const $languageSelectorLarge = $('.language-selector-large-wrapper');

        if ($languageSelectorLarge.length > 0) {
            $languageSelectorLarge.each((index, element) => {
                const $this = $(element);

                const $languageSelector = $('.language-selector', $this);
                const $languageSelectorList = $('.language-selector-list',
                    $this);
                const $languageSelectorItem = $('.language-selector-item',
                    $languageSelectorList);
                const $languageSelectorItemCount = $languageSelectorItem.length;

                if ($languageSelectorItemCount > 4) {
                    const $showMore = $('.language-selector-show-more', $this);
                    const $showMoreLabel = $showMore.attr('data-more');
                    const $showLessLabel = $showMore.attr('data-less');
                    const $rowHeight = $languageSelectorItem.outerHeight(
                        true);

                    let $currentHeight;

                    const setShowLabel = (label) => {
                        $showMore.text(label);
                    };

                    const setListHeight = () => {
                        let root = document.documentElement;
                        root.style.setProperty(
                            '--iql-language-selector-list-row-height',
                            $rowHeight + 'px');

                        if ($languageSelectorItemCount > 5) {
                            $languageSelectorList.height(
                                3 * $rowHeight + 1 + 'px'); // + 1px -> inclusive button border
                        }

                        $currentHeight = $languageSelectorList.height();
                    };

                    setListHeight();

                    $languageSelectorList.addClass(isFoldableClass);
                    $showMore.addClass(isVisibleClass);

                    $showMore.on('click', () => {
                        $languageSelectorList.toggleClass(isVisibleClass);
                        $languageSelector.toggleClass(isOpen);

                        if ($languageSelectorList.hasClass(isVisibleClass)) {
                            $languageSelectorList.css('height', 'auto');
                            $languageSelectorList.removeClass(isFoldableClass);
                            setShowLabel($showLessLabel);
                        } else {
                            $languageSelectorList.css('height', $currentHeight);
                            $languageSelectorList.addClass(isFoldableClass);
                            setShowLabel($showMoreLabel);
                        }
                    });
                }
            });
        }
    };

    languageSelectorSmall = () => {
        const $languageSelectorSmall = $('.language-selector-small-wrapper');

        if ($languageSelectorSmall.length > 0) {
            $languageSelectorSmall.each((index, element) => {
                const $this = $(element);

                const $languageSelector = $('.language-selector', $this);
                const $languageSelectorList = $('.language-selector-list',
                    $this);
                const $languageSelectorItem = $('.language-selector-item',
                    $languageSelectorList);
                const $languageSelectorItemCount = $languageSelectorItem.length;

                if ($languageSelectorItemCount > 2) {
                    const $showMore = $('.language-selector-show-more', $this);
                    const $showMoreLabel = $showMore.attr('data-more');
                    const $showLessLabel = $showMore.attr('data-less');

                    const setShowLabel = (label) => {
                        $showMore.text(label);
                    };

                    $showMore.addClass(isVisibleClass);

                    $showMore.on('click', () => {
                        $languageSelectorList.toggleClass(isVisibleClass);
                        $languageSelector.toggleClass(isOpen);

                        if ($languageSelectorList.hasClass(isVisibleClass)) {
                            setShowLabel($showLessLabel);
                            $languageSelectorItem.not(':nth-child(-n+2)').css('display', 'block');
                        } else {
                            setShowLabel($showMoreLabel);
                            $languageSelectorItem.not(':nth-child(-n+2)').css('display', 'none');
                        }
                    });
                }
            });
        }
    };

    init();
};
