export default () => {
    let languageSelectElement;
    let mobileTotalElement;
    const init = () => {
        languageSelectElement = document.querySelector('[name="tmms-customer-input-value-2"]');
        if (languageSelectElement !== null) {
            languageSelectElement.style.display = 'none';
            const innerHtml = getFields(languageSelectElement.options);
            languageSelectElement.insertAdjacentHTML('afterend',
                '<div id="tiles-con">' + innerHtml + '</div>');
            const options = document.querySelectorAll('.tiles-option');
            for (const option of options) {
                option.addEventListener('click',
                    (event) => setValue(event.target, languageSelectElement));
                if (option.dataset.value === languageSelectElement.value) {
                    option.classList.add('selected');
                }
            }
        }

        mobileTotalElement = document.querySelector('#checkout-total-mobile');
        if (mobileTotalElement !== null) {
            const footer = document.querySelector('footer');
            footer.style.marginBottom = mobileTotalElement.offsetHeight + 'px';
            window.addEventListener('resize', function() {
                footer.style.marginBottom = mobileTotalElement.offsetHeight + 'px';
            });
        }
    };
    init();
};

const getFields = (options) => {
    let htmlString = '';
    for (let index = 1; index < options.length; index++) {
        htmlString += '<div class="tiles-option" data-value="' +
            options[index].value + '">' + options[index].value + '</div>';
    }
    return htmlString;
};

const setValue = (element, originElement) => {
    const options = document.querySelectorAll('.tiles-option');
    for (const option of options) {
        option.classList.remove('selected');
    }
    element.classList.add('selected');
    originElement.value = element.dataset.value;
    originElement.dispatchEvent(new Event('change'));
};
