import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;

    let initStickyFooter,
    initLocationSubNavigation;

    init = () => {
        initStickyFooter();
        initLocationSubNavigation();
    };

    initLocationSubNavigation = () => {
        const $locationpage = $('.dok-locationpage');
        if ($locationpage.length === 0) {
            return; // don't do anything on different page types
        }

        const $submenu = $('.sub-navigation');
        const $header = $('header');
        $(window).on('scroll', () => {
            const submenuBoundingRect = $submenu[0].getBoundingClientRect();
            const headerBoundingRect = $header[0].getBoundingClientRect();

            if (submenuBoundingRect.top <= headerBoundingRect.height) {
                $submenu.addClass('position-sticky');
            } else {
                $submenu.removeClass('position-sticky');
            }
        });
    };

    initStickyFooter = () => {
        let deactivateSticky = false;

        const activateStickyFooterByScrollHeight = () => {
            const scrollTopPosition = $(window).scrollTop();
            const documentHeight = $(document).height();
            const windowHeight = $(window).height();
            const scrollPercent = (scrollTopPosition / (documentHeight - windowHeight));
            const $stickyFooter = $('.sticky-footer');

            if (scrollPercent > 0.25 && !deactivateSticky) {
                $stickyFooter.removeClass('d-none');
            } else {
                $stickyFooter.addClass('d-none');
            }
        };

        $('.to-contact-form').click(function() {
            const $form = $('.frame-type-form_formframework').last();
            if ($form) {
                $form[0].scrollIntoView();
            }
        });

        $(window).on('scroll', () => {
            activateStickyFooterByScrollHeight();
        });
        $('input, textarea').on('focus', () => {
            deactivateSticky = true;
            activateStickyFooterByScrollHeight();
        }).on('blur', () => {
            deactivateSticky = false;
            activateStickyFooterByScrollHeight();
        });
        activateStickyFooterByScrollHeight();
    };

    init();
};
