import $ from 'jquery/dist/jquery.slim';

import {viewportSize} from '../utils/viewport';

export default () => {
    let init;
    let initMainNavigationDesktop;
    let initMainNavigationMobile;

    let $mainNavigation = $('.main-navigation');
    let $mainNavigationItem = $('.main-navigation__item', $mainNavigation);
    let $mainNavigationItemIcon = $('.icon', $mainNavigationItem);
    let $mainNavigationItemLevel1 = $('.main-navigation__item-level-1',
        $mainNavigation);
    let $mainNavigationItemLevel2 = $(
        '.main-navigation__item-level-2.has-subpages',
        $mainNavigation);
    let mainNavigationSpacerPage = $('.main-navigation__spacer',
        $mainNavigation);
    let $mainNavigationTrigger = $('.js-main-navigation-trigger');
    let isOpenClass = 'is-open';
    let isMobileInitialized = false;

    init = () => {
        viewportSize.subscribe(size => {
            initMainNavigationDesktop(size);
            initMainNavigationMobile(size);
        });
    };

    initMainNavigationDesktop = (size) => {
        if (size === 'xxl') {
            isMobileInitialized = false;

            // Remove event handler for mobile
            $mainNavigationTrigger.off();
            $mainNavigationItemIcon.off();
            mainNavigationSpacerPage.off();

            // Show/Hide 1st level
            $mainNavigationItemLevel1.hover((event) => {
                let $this = $(event.currentTarget);

                $this.toggleClass(isOpenClass);
            });

            // Workaround for toggling classes if the mouse pointer remains in the same position after a page load
            $mainNavigationItemLevel1.one('mousemove', (event) => {
                let $this = $(event.currentTarget);

                if (!$($this).hasClass(isOpenClass)) {
                    $this.addClass(isOpenClass);
                }
            });

            // Show/Hide 2nd level
            $mainNavigationItemLevel2.hover((event) => {
                let $this = $(event.currentTarget);
                let $level3 = $('.main-navigation__level-3', $this);
                let $level2Height = $this.position().top + $this.height();
                let $level3Height = $level3.height();

                // Workaround for too small level 3 height
                if ($level2Height > $level3Height) {
                    $level3.height($level2Height);
                }

                $this.toggleClass(isOpenClass);
            });
        }
    };

    initMainNavigationMobile = (size) => {
        if (size !== 'xxl' && !isMobileInitialized) {
            isMobileInitialized = true;

            // Remove event handler for desktop
            $mainNavigationItemLevel1.off();
            $mainNavigationItemLevel1.off();
            $mainNavigationItemLevel2.off();

            // Show/Hide main navigation (burger menu)
            $mainNavigationTrigger.on('click', (event) => {
                let $this = $(event.currentTarget);

                $this.toggleClass(isOpenClass);
                $mainNavigation.toggleClass(isOpenClass);
            });

            // Show/Hide sub navigation when clicking on the icon on the right side
            $mainNavigationItemIcon.on('click', (event) => {
                let $this = $(event.currentTarget);
                let $currentNavigationItem = $this.closest(
                    '.main-navigation__item');

                $currentNavigationItem.toggleClass(isOpenClass);
                event.stopPropagation();
                event.preventDefault();
            });

            // Show/Hide sub navigation when clicking on spacer navigation items (TYPO3 → 'Menu separator' page)
            mainNavigationSpacerPage.on('click', (event) => {
                let $this = $(event.currentTarget);
                let $currentNavigationItem = $this.closest(
                    '.main-navigation__item');

                $currentNavigationItem.toggleClass(isOpenClass);
            });
        }
    };

    init();
};
