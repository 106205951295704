import debug from '../modules/debug.js';

export default function CustomerIdGenerator(prefixLength, randomLength) {
    /**
     * A generator for customer IDs
     *
     * @param {number} prefixLength the length of the prefix, the prefix will be forced to this length(cut off or prepend with "0")
     * @param {number} randomLength the length of the random part
     * @constructor
     */

    /**
     * Generates a customer ID
     *
     * @method generate
     * @param {string} prefix gets prepended, will get forced to set prefixLength
     * @returns {string}
     */
    this.generate = function(prefix) {
        prefix = prefix.toString();

        let randomPart = '';

        try {
            // Generate random string
            for (let i = 0; i < randomLength; i++) {
                const x = Math.floor(Math.random() * 10);
                randomPart += x.toString();
            }
        } catch (e) {
            debug.log(e);
            randomPart = new Date().getTime().toString();
            randomPart = randomPart.slice(randomPart.length - randomLength);
        }

        // Take care the prefix is exact the given length, fill up with "0" from left
        if (prefix.length !== prefixLength) {
            // Cut of if too long
            prefix = prefix.substring(0, prefixLength);
            // Fill up with "0" from left if to short
            for (let j = prefix.length; j < prefixLength; j++) {
                prefix = '0' + prefix;
            }
        }

        return prefix + randomPart;
    };
}
