import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let initYoutubeModal;

    init = () => {
        initYoutubeModal();
    };

    initYoutubeModal = () => {
        let $onlineCourseTutorialVideo = $('.online-course-tutorial--video');

        if ($onlineCourseTutorialVideo.length > 0) {
            $onlineCourseTutorialVideo.each((index, element) => {
                const $this = $(element);

                const $modalElement = $('.iql-modal-video', $this);
                const $modalVideoIframe = $('.iq-modal-video__iframe',
                    $modalElement);
                const $modalVideoIframeSrc = $modalVideoIframe.attr(
                    'data-iql-iframe-src');
                const $youTubePrivacyBtn = $('.online-course-tutorial__image-btn');
                const $modalImage = $('.online-course-tutorial__image');

                $modalElement.on('hide.bs.modal', () => {
                    $modalVideoIframe.attr('src', '');
                });

                $modalElement.on('show.bs.modal', () => {
                    $modalVideoIframe.attr('src', $modalVideoIframeSrc);
                });

                $youTubePrivacyBtn.on('click', () => {
                    $('.online-course-tutorial__image-text').attr('hidden', true);
                    $modalImage.attr('data-bs-toggle', 'modal');
                    $modalImage.attr('data-bs-target', $youTubePrivacyBtn.attr('data-bs-target'));
                    $modalVideoIframe.attr('src', $modalVideoIframeSrc);
                });
            });
        }
    };

    init();
};
