import $ from 'jquery/dist/jquery.slim';

export let siteLanguage = 'de-de'; // 'de' -> Default

export default () => {
    getSiteLanguage();
};

export function getSiteLanguage() {
    let htmlLangAttribute = $('html').attr('lang');

    siteLanguage = htmlLangAttribute.split('-', 1)[0]; // 'de-de' -> 'de'
    window.siteLanguage = siteLanguage;
}
