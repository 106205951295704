import $ from 'jquery/dist/jquery.slim';

export default () => {
    let init;
    let initScrollToTop;

    init = () => {
        initScrollToTop();
    };

    initScrollToTop = () => {
        let $scrollToTop = $('.scroll-to-top-trigger');

        $scrollToTop.on('click', () => {
            window.scrollTo({top: 0});
            return false;
        });
    };

    init();
};
