import $ from 'jquery/dist/jquery.slim';

import {viewportSizeLgDown} from '../utils/viewport';

const $btnGroupDropdown = $('.btn-group--dropdown-on-mobile').
    not('.no-initial-transform');

export default () => {
    if ($btnGroupDropdown.length > 0) {
        initButtonGroupDropDown($btnGroupDropdown);
    }
};

export function initButtonGroupDropDown(selector) {
    const isTransformed = 'is-transformed';
    const isSelected = 'is-selected';

    $.each(selector, (index, element) => {
        const $this = $(element);

        let $selectPlaceholder = $this.attr('aria-label');
        let $selectMobilePlaceholder = $this.data('mobile-placeholder');

        if ($selectPlaceholder === '' && $selectMobilePlaceholder === '') {
            $selectPlaceholder = 'Please select';
        } else if ($selectMobilePlaceholder) {
            $selectPlaceholder = $selectMobilePlaceholder;
        }

        const transformStructureToDropDown = (size) => {
            if (!$this.hasClass(isTransformed)) {
                if (size === 'lg-down') {
                    const $dropdownMenuHtmlWrapper = '<div class="dropdown-menu"/>';
                    const $dropdownButtonHtml = `
                        <button type="button" class="btn btn-select dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                            ${$selectPlaceholder}
                        </button>
                    `;

                    $this.children().
                        wrapAll($dropdownMenuHtmlWrapper);
                    $this.prepend($dropdownButtonHtml);
                    $this.addClass(isTransformed);
                    $this.show();

                    addSelectHandling();
                }
            }
        };

        const addSelectHandling = () => {
            const $selectOption = $('.btn-radio-group', $this);
            const $selectButton = $('.btn-select', $this);

            $selectOption.on('click', (event) => {
                const $option = $(event.currentTarget);
                const $optionText = $option.text();
                $selectButton.text($optionText);
                $this.addClass(isSelected);
            });
        };

        viewportSizeLgDown.subscribe(size => {
            transformStructureToDropDown(size);
        });
    });
}
