import $ from 'jquery/dist/jquery.slim';
import {
    getCountries, getCountryCallingCode, parsePhoneNumber,
} from 'libphonenumber-js';

export default () => {
    internationalPhoneNumber();
};

export const internationalPhoneNumber = () => {
    const countries = getCountries();
    const $internationalPrefixField = $('.form-select-international-prefix');

    if ($internationalPrefixField.length > 0) {
        $internationalPrefixField.each((index, element) => {
            const $this = $(element);

            const $form = $this.closest('form');
            const $nationalPhoneNumberField = $(
                '.form-control-national-phone-number', $form);
            const $submitButton = $('[type="submit"]', $form);
            const isInvalid = 'is-invalid';

            let validStateForm = false;
            let validateStatePhoneNumber = false;

            // Integrate country codes from 'libphonenumber-js'
            const initCountryCodes = () => {
                const optionString = `
                    <option value="">${$this.attr('placeholder')}</option>
                `;
                $this.append(optionString);

                // prioritized calling Codes
                // reverse so that DE is in front

                const prioritizedCountryCodes = ['DE', 'AT', 'GB', 'FR', 'ES', 'IT'];
                prioritizedCountryCodes.reverse();
                for (const countryCode of prioritizedCountryCodes) {
                    const index = countries.indexOf(countryCode);
                    countries.unshift(countries[index]);
                }

                for (const country of countries) {
                    if (country) {
                        const countryCode = getCountryCallingCode(country);
                        const optionString = `
                    <option value="+${countryCode}">
                        ${country}&nbsp;+${countryCode} 
                    </option>
                `;
                        $this.append(optionString);
                    }
                }
            };
            initCountryCodes();

             
            const checkPhoneNumber = () => {
                // Check only if the phone number field has min 2 character
                // for suppressing the error message from 'libphonenumber-js'
                if ($this.val() !== '' && $nationalPhoneNumberField.val().length > 1) {
                    const $phoneNumber = parsePhoneNumber(
                        $this.val() + $nationalPhoneNumberField.val());
                    const $errorMessageContainer = $nationalPhoneNumberField.parent().
                        find('.invalid-feedback');
                    const $errorMessage = $nationalPhoneNumberField.data(
                        'validator-error');
                    if ($phoneNumber.isValid()) {
                        $errorMessageContainer.remove();
                        $nationalPhoneNumberField.removeClass(isInvalid);
                        $nationalPhoneNumberField.val(
                            $phoneNumber.nationalNumber);
                        validateStatePhoneNumber = true;
                    } else {
                        if ($errorMessageContainer.length === 0) {
                            $nationalPhoneNumberField.after(
                                `<div class="invalid-feedback">${$errorMessage}</div>`);
                            $nationalPhoneNumberField.addClass(isInvalid);
                        }
                        validateStatePhoneNumber = false;
                    }
                }
            };
             

            $internationalPrefixField.on('blur', () => {
                checkPhoneNumber();
            });

            $nationalPhoneNumberField.on('blur', () => {
                checkPhoneNumber();
            });

            const checkIfFormIsValid = () => {
                checkPhoneNumber();

                validStateForm = validateStatePhoneNumber !== false;
            };

            $submitButton.on('click', () => {
                checkIfFormIsValid();

                if (!validStateForm) {
                    event.preventDefault();
                }
            });
        });
    }
};
