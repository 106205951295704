import $ from 'jquery/dist/jquery.slim';

import CustomerIdGenerator from '../utils/customer-id-generator';

import debug from './debug.js';

/**
 * Applies the tracking events
 *
 * @class Tracking
 * @namespace Forms
 * @constructor
 */
export function TrackingForms() {
    const that = this;
    const gen = new CustomerIdGenerator(4, 6);

    /**
     * Triggers an event on the tracking API
     *
     * @method apiTrackingEvent
     * @param {string} category
     * @param {string} action
     * @param {string} trackingId (optional)
     *
     */
    this.apiTrackingEvent = function(category, action, trackingId) {
        // Only if dataLayer API is given (Google Tag Manager)
        if (typeof /** @global */ dataLayer !== 'undefined') {
            const data = {
                'action': action,
                'category': category,
                'url': window.location.href,
            };

            if (typeof trackingId !== 'undefined') {
                data['trackingId'] = trackingId;
            }

            dataLayer.push(data);

            return true;
        } else {
            debug.log(
                'ERROR: dataLayer API is not available! Should be enabled by Google Tag Manager. Event tracking is not available.');
        }

        return false;
    };

    /**
     * Applies the customer id to form for regular location forms
     *
     * @method applyCustomerId
     * @return void
     */
    this.applyCustomerId = function(locationIdForCustomerId) {
        if (typeof /** @global */ locationIdForCustomerId !== 'undefined') {
            $('.js-field-customerId').
                val(gen.generate(locationIdForCustomerId));
        }
    };

    /**
     * Applies the customer id for location forms with location finder
     *
     * @method applyCustomerIdOnFinderForm
     * @param {string} locationId The ID for the location
     * @param {object} formChildElement An child element of the form, typically it's the location finder inside the form
     * @return void
     */
    this.applyCustomerIdOnFinderForm = function(
        locationId, formChildElement) {
        $(formChildElement).
            parents('.js-form-tracking-location').
            find('.form-customerId').
            val(gen.generate(locationId));
    };

    /**
     * Applies the eventlisteners
     *
     * @method applyEventListeners
     * @return void
     */
    this.applyEventListeners = function() {
        // event listener for all location based forms
        $('.js-form-tracking-location form').submit(function() {
            const formContainer = $(this).
                parents('.js-form-tracking-location');

            that.apiTrackingEvent(formContainer.data('trackingcategory'),
                'Formular gesendet',
                formContainer.find('.form-customerId').attr('value'));

            return true;
        });

        // event listener for all non-location based forms
        $('.js-form-tracking form').submit(function() {
            const formContainer = $(this).parents('.js-form-tracking');

            that.apiTrackingEvent(formContainer.data('trackingcategory'),
                'Formular gesendet');

            return true;
        });
    };
}

export default function() {
    // Set defaults
    let trackingForms;
    trackingForms = new TrackingForms();
    trackingForms.applyCustomerId();
    // Push data to GTM
    // trackingForms.applyEventListeners();

    if (typeof dataLayer === 'undefined') {
        debug.log(
            'ERROR: dataLayer API is not available! Should be enabled by Google Tag Manager. Event tracking is not available.');
    }
}
