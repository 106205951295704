import $ from 'jquery/dist/jquery.slim';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _uniqBy from 'lodash/uniqBy';
import {DateTime} from 'luxon';

import chain from '../utils/chain';
import {siteLanguage} from '../utils/language';
import leadingZero from '../utils/leading-zero';
import {viewportSizeLgDown} from '../utils/viewport';

import {initButtonGroupDropDown} from './button-group';
import debug from './debug.js';
import {TrackingForms} from './tracking-forms';

export default () => {
    let init;

    const $consultationForm = $('form[id^="form_consultation"]'); // id="form_contact-123"

    if ($consultationForm.length > 0) {
        let getFormId;
        let chooseLocation;
        let chooseCourselanguage;
        let getAppointments;
        let checkNumberOfLocations;
        let checkNumberOfCourselanguages;
        let filterAppointments;
        let emptyDateFieldGroupArea;
        let emptyTimeFieldGroupArea;
        let radioGroupItemTemplate;
        let compiledDateRadioGroupItemTemplate;
        let compiledTimeRadioGroupItemTemplate;
        let transformRadioButtonGroup;
        let chooseDate;
        let chooseTime;
        let setSelectedDateForTimeFieldset;
        let togglePrivacyFormElement;
        let setCustomerId;
        let formNavigation;
        let enableNextButton;
        let disableAllNextButtons;
        let showFormActionNavigation;
        let hideFormActionNavigation;
        let setStepStatus;
        let setStepIsActive;
        let showProgressBar;
        let setFranchisePartnerParameter;

        const isActive = 'is-active';
        const isHidden = 'is-hidden';
        const isChecked = 'is-checked';
        let $formId;

        const $locationFieldset = $('.form-element-fieldset-location',
            $consultationForm);
        const $locationField = $('[id$="-location"]');
        const $courselanguageFieldset = $('.form-element-fieldset-courselanguage',
            $consultationForm);
        const $courselanguageField = $('[id$="-courselanguage"]');
        const $dateFieldset = $('.form-element-fieldset-date',
            $consultationForm);
        const $dateRadioButtonGroup = $('.btn-group', $dateFieldset);
        const $timeFieldset = $('.form-element-fieldset-time',
            $consultationForm);
        const $timeRadioButtonGroup = $('.btn-group', $timeFieldset);
        const $contactFieldset = $('.form-element-fieldset-contact',
            $consultationForm);
        const $formActions = $('.form-actions');
        const $formActionsPreviousButton = $('.fieldset-previous-button',
            $formActions);
        const $progressBar = $('.iql-progress-bar', $consultationForm);
        const $privacyFranchiseLink = $('.js-privacy-franchise-link',
            $consultationForm);
        const $privacyFranchiseHref = $privacyFranchiseLink.attr('href');
        const $locations = $('[value!=""][value]', $locationFieldset); // Options with value
        const $locationCount = $locations.length;
        const $courselanguages = $('[value!=""][value]', $courselanguageFieldset); // Options with value
        const $courselanguageCount = $courselanguages.length;
        const $courselanguageMessageContainer = $('.courselanguage-message',
            $consultationForm);

        init = () => {
            getFormId();
            checkNumberOfLocations();
            formNavigation();
        };

        setFranchisePartnerParameter = (locationType, locationId) => {
            // If franchise partner
            if (locationType === 1) {
                $privacyFranchiseLink.attr('href',
                    $privacyFranchiseHref + '?franchiseIdentifier=' +
                    locationId);
            }
        };

        getFormId = () => {
            $formId = $consultationForm.attr('id').split('-').pop();
        };

        checkNumberOfLocations = () => {
            // Check number of locations
            if ($locationCount > 1) {
                // Multiple locations
                $locationFieldset.addClass(isActive);
                setStepStatus('.step-1');
                chooseLocation();
                if ($courselanguageCount > 1) {
                    $('.fieldset-next-date', $locationFieldset).remove();
                } else {
                    $('.fieldset-next-courselanguage', $locationFieldset).remove();
                }
            } else if ($locationCount < 1) {
                // Throw error if no locations available
                debug.log('[ERROR] No locations available!');
            } else {
                // One location
                const $locationId = $locations.val();
                const $locationType = $locations.data('franchise');

                // Set location as selected (Not really important)
                $locations.prop('selected', true);

                $('.fieldset-previous-button', $courselanguageFieldset).remove();

                disableAllNextButtons($locationFieldset);
                togglePrivacyFormElement($locationType, $locationId);
                setCustomerId($locationId);
                getAppointments($locationId, $locationType);
                checkNumberOfCourselanguages();
            }
        };

        chooseLocation = () => {
            $locationField.on('change', (event) => {
                const $this = $(event.currentTarget);
                const $locationId = $this.val();
                const $locationType = $this.find(':selected').data('franchise');

                disableAllNextButtons($locationFieldset);
                enableNextButton($locationFieldset);
                togglePrivacyFormElement($locationType, $locationId);
                setCustomerId($locationId);
                getAppointments($locationId, $locationType);
            });
        };

        checkNumberOfCourselanguages = () => {
            // Check number of locations
            if ($courselanguageCount > 1) {
                // Multiple locations
                $courselanguageFieldset.addClass(isActive);
                setStepStatus('.step-2');
                chooseCourselanguage();
                // Remove previous button on date fieldset
                $('.fieldset-previous-location', $dateFieldset).remove();
            } else if ($courselanguageCount < 1) {
                // Throw error if no locations available
                debug.log('[ERROR] No course languages available!');
            } else {
                // Set courselanguage as selected (Not really important)
                $courselanguages.prop('selected', true);
                setStepStatus('.step-3');
                $dateFieldset.addClass(isActive);
                $courselanguageFieldset.removeClass(isActive);
                // Remove previous button on date fieldset
                $('.fieldset-previous-courselanguage', $dateFieldset).remove();
                if ($locationCount === 1) {
                    $('.fieldset-previous-location', $dateFieldset).remove();
                }

                disableAllNextButtons($courselanguageFieldset);
            }
        };

        chooseCourselanguage = () => {
            $courselanguageField.on('change', () => {
                let stringToElement = '<p class="iql-text-small" id="course-language-text">' + $courselanguageField.children(':selected').data('message') + '</p>';
                disableAllNextButtons($courselanguageFieldset);
                enableNextButton($courselanguageFieldset);
                $('#course-language-text').remove();
                $courselanguageMessageContainer.append(stringToElement);
            });
        };

        getAppointments = (locationId, franchise) => {
            const url = new URL(location);
            const originUrl = url.origin;
            const locationParameter = 'loc_timeslot_iql';
            const franchiseParameter = 'franchise';

            const apiUrl = `${originUrl}/?${locationParameter}=${locationId}&${franchiseParameter}=${franchise}`;

            fetch(apiUrl).then(async (data) => {
                if (data.ok) {
                    const jsonData = await data.json();
                    filterAppointments(JSON.parse(jsonData[0]));
                    showProgressBar();
                }
            }).catch(function(xhr) {
                debug.log('[Error: API]', xhr);
            });
        };

        filterAppointments = (appointments) => {
            const getDateTimeObjects = _filter(appointments, (o) => {
                return o.link;
            });

            const getDateTime = _map(getDateTimeObjects, (o) => {
                return o.dateTime;
            });

            const getDateFromArray = (o) => {
                return [o[0], o[1], o[2]].toString(); // 2022, 10, 20
            };

            const getDateAsISOFromArray = (o) => {
                return [
                    o[0] + '-' + leadingZero(o[1], 2) + '-' +
                    leadingZero(o[2], 2)].toString(); // 2022-10-20
            };

            const getTimeFromArray = (o) => {
                // Convert to ISO format -> '10:00' (we need 2 digits for hour and minutes)
                return [
                    leadingZero(o[3], 2) + ':' +
                    leadingZero(o[4], 2)].toString();
            };

            // Flatten array like -> {date: '2022,6,28', ... time: []}
            const flattenDateTimeArray = _map(getDateTime, (o) => {
                return {
                    'date': getDateFromArray(o),
                    'dateISO': getDateAsISOFromArray(o),
                    'times': getTimeFromArray(o),
                };
            });

            // Creates a duplicate-free (date) version of the 'flattenDateTimeArray'
            const uniqByDate = _uniqBy(flattenDateTimeArray, (o) => {
                return o.date;
            });

            // Prepare 'time' array for times collection
            const emptyTimeArrayUniqByDate = _map(uniqByDate, (o) => {
                return {
                    'date': o.date,
                    'dateISO': o.dateISO,
                    'times': [],
                };
            });

            // Merge all times on the same date into 'time' array
            // This is the end result of all transformations
            const appointmentsResultArray = emptyTimeArrayUniqByDate.map(
                (item) => {
                    flattenDateTimeArray.map((item2) => {
                        if (item.date === item2.date) {
                            item.times.push(item2.times);
                        }
                    });

                    return item;
                });

            chooseDate(appointmentsResultArray);
        };

        emptyDateFieldGroupArea = () => {
            $dateRadioButtonGroup.empty();
        };

        emptyTimeFieldGroupArea = () => {
            $timeRadioButtonGroup.empty();
        };

        radioGroupItemTemplate = (
            labelText = '',
            inputValue = '',
            fieldsGroupLabel = '',
            fieldsGroupId = 0,
            index = 0,
            dateTime = '',
            dateTimeISO = '') => (`
            <input required="required" class="btn-check" id="form_consultation-${fieldsGroupId}-consultation${fieldsGroupLabel}-${index}" type="radio"
                   name="tx_form_formframework[form_consultation-${fieldsGroupId}][consultation${fieldsGroupLabel}]" value="${inputValue}" data-date-time="${dateTime}" data-date-time-iso="${dateTimeISO}">
            <label class="btn btn-radio-group" for="form_consultation-${fieldsGroupId}-consultation${fieldsGroupLabel}-${index}">
                <span class="form-check-label-text">${labelText}</span>
            </label>
    `);

        chooseDate = (appointments) => {
            const maxNumberForDateFields = 6;

            emptyDateFieldGroupArea();
            emptyTimeFieldGroupArea(); // Also empty time field group area

            compiledDateRadioGroupItemTemplate = ''; // Empty compiled template

            $(appointments).each((index, element) => {
                const fieldsGroupId = $formId;
                const fieldsGroupLabel = 'Date';

                const date = element['date'];
                const dateISO = element['dateISO'];
                const isoDateAsString = new Date(dateISO).toISOString();
                const dateTime = DateTime.fromISO(isoDateAsString);
                const onlyDateISO = dateTime.toISODate();
                const formattedDate = dateTime.setLocale(
                    siteLanguage).weekdayLong + '<br/>' +
                    dateTime.setLocale(siteLanguage).
                    toLocaleString({day: '2-digit', month: '2-digit'});
                compiledDateRadioGroupItemTemplate += radioGroupItemTemplate(
                    formattedDate, onlyDateISO, fieldsGroupLabel, fieldsGroupId,
                    index, date, onlyDateISO);

                return index < maxNumberForDateFields - 1;
            });

            $dateRadioButtonGroup.append(compiledDateRadioGroupItemTemplate);
            initButtonGroupDropDown($dateRadioButtonGroup);
            transformRadioButtonGroup(); // Replace <br> tags ...

            const $dateField = $('.btn-check', $dateFieldset);

            $dateField.on('click', (event) => {
                const $this = $(event.currentTarget);
                const $selectedDate = $this.data('date-time');
                const $selectedDateAsISO = $this.data('date-time-iso');

                const times = chain(appointments).
                fn(_find, {'date': $selectedDate}).
                fn(_pick, 'times').
                value();

                enableNextButton($dateFieldset);
                setSelectedDateForTimeFieldset($selectedDateAsISO);
                chooseTime(times);
            });
        };

        chooseTime = (times) => {
            emptyTimeFieldGroupArea();
            compiledTimeRadioGroupItemTemplate = ''; // Empty compiled template

            $(times.times).each((index, element) => {
                const fieldsGroupId = $formId;
                const fieldsGroupLabel = 'Time';

                const isoTime = DateTime.fromISO(element);
                const simpleTime = isoTime.toLocaleString(DateTime.TIME_24_SIMPLE);
                const formattedTime = isoTime.setLocale(siteLanguage).
                toLocaleString(DateTime.TIME_SIMPLE);

                compiledTimeRadioGroupItemTemplate += radioGroupItemTemplate(
                    formattedTime, simpleTime, fieldsGroupLabel,
                    fieldsGroupId, index);
            });

            $timeRadioButtonGroup.append(compiledTimeRadioGroupItemTemplate);
            initButtonGroupDropDown($timeRadioButtonGroup);

            const $timeField = $('.btn-check', $timeFieldset);

            $timeField.on('click', () => {
                enableNextButton($timeFieldset);
            });
        };

        setSelectedDateForTimeFieldset = (selectedDateAsISO) => {
            let formattedDate;

            const transformDate = (selectedDateAsISO) => {
                const dateTime = DateTime.fromISO(selectedDateAsISO);
                formattedDate = dateTime.setLocale(
                    siteLanguage).weekdayShort + '. ' +
                    dateTime.setLocale(siteLanguage).
                    toLocaleString(
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        });
            };

            transformDate(selectedDateAsISO);

            const setTransformedDate = (formattedDate) => {
                const $targetElement = $('.fieldset-time-insert-date',
                    $consultationForm);

                $targetElement.text(formattedDate);
            };

            setTransformedDate(formattedDate);
        };

        transformRadioButtonGroup = () => {
            const transform = (size) => {
                if (size === 'lg-down') {
                    const $labelText = $(
                        '.btn-group--dropdown-on-mobile .form-check-label-text',
                        $consultationForm);

                    // Replace <br> with comma in label text
                    $labelText.each((index, element) => {
                        const $this = $(element);
                        const $labelTextAsHtml = $this.html();
                        const $removedBreakLines = $labelTextAsHtml.replace(
                            '<br>', ', ');
                        $this.html($removedBreakLines);
                    });
                }
            };
            viewportSizeLgDown.subscribe((size) => {
                transform(size);
            });
        };

        togglePrivacyFormElement = (locationType, locationId) => {
            const $privacyFormElement = $('.form-element-privacy',
                $consultationForm);
            const $privacyFranchiseFormElement = $(
                '.form-element-privacy-franchise', $consultationForm);

            if (locationType === 0) {
                // Is not a franchise partner
                $privacyFormElement.removeClass(isHidden);
                $privacyFranchiseFormElement.addClass(isHidden);
            } else {
                // Is a franchise partner
                $privacyFormElement.addClass(isHidden);
                $privacyFranchiseFormElement.removeClass(isHidden);
                setFranchisePartnerParameter(locationType, locationId);
            }
        };

        setCustomerId = (locationId) => {
            // Create a new tracking object with default values (customerId)
            let trackingContactForm;
            trackingContactForm = new TrackingForms();

            trackingContactForm.applyCustomerId(locationId);
        };

        formNavigation = () => {
            const $fieldsetActions = $('.fieldset-actions', $consultationForm);
            const $fieldsetNextButton = $('.fieldset-next-button',
                $fieldsetActions);
            const $fieldsetPreviousButton = $('.fieldset-previous-button',
                $fieldsetActions);

            // Initially the next buttons are disabled
            $fieldsetNextButton.on('click', (event) => {
                const $this = $(event.currentTarget);
                const $currentFieldset = $this.closest(
                    '.form-element-fieldset');
                const $nextFieldset = $currentFieldset.nextAll(
                    '.form-element-fieldset').first();
                const $navigateToStepData = $this.data(
                    'navigate-to-step-selector');

                $currentFieldset.toggleClass(isActive);
                $nextFieldset.toggleClass(isActive);

                setStepStatus($navigateToStepData);
                if ($navigateToStepData === '.step-2') {
                    checkNumberOfCourselanguages();
                }
            });

            $fieldsetPreviousButton.on('click', (event) => {
                const $this = $(event.currentTarget);
                const $currentFieldset = $this.closest(
                    '.form-element-fieldset');
                let $previousFieldset = $currentFieldset.prevAll(
                    '.form-element-fieldset').first();
                const $navigateToStepData = $this.data(
                    'navigate-to-step-selector');

                if ($navigateToStepData === '.step-1') {
                    $previousFieldset = $currentFieldset.prevAll(
                        '.form-element-fieldset').last();
                }

                $currentFieldset.toggleClass(isActive);
                $previousFieldset.toggleClass(isActive);

                setStepStatus($navigateToStepData);

                if ($navigateToStepData === '.step-2' && $courselanguages.val() > 0) {
                    enableNextButton($courselanguageFieldset);
                }
                if ($navigateToStepData === '.step-1' && $locations.val() > 0) {
                    enableNextButton($locationFieldset);
                }
            });

            // Event handler for show/hide form action navigation
            showFormActionNavigation();
            hideFormActionNavigation();
        };

        enableNextButton = (fieldset) => {
            const $nextButton = $('.fieldset-next-button',
                fieldset);

            $nextButton.removeAttr('disabled');
        };

        disableAllNextButtons = () => {
            const $nextButton = $('.fieldset-next-button', $consultationForm);

            $nextButton.attr('disabled', true);
        };

        showFormActionNavigation = () => {
            const $nextButtonOnPreviousFieldset = $('.fieldset-next-button',
                $timeFieldset);

            $nextButtonOnPreviousFieldset.on('click', () => {
                $formActions.show();
            });
        };

        hideFormActionNavigation = () => {
            $formActionsPreviousButton.on('click', () => {
                const $this = $(event.currentTarget);
                const $navigateToStepData = $this.data(
                    'navigate-to-step-selector');

                setStepStatus($navigateToStepData);

                $formActions.hide();

                // Show previous fieldset and hide current fieldset
                $contactFieldset.removeClass(isActive);
                $timeFieldset.addClass(isActive);
            });
        };

        setStepStatus = (currentStep) => {
            $(currentStep).prevAll('.step').addClass(isChecked);
            $(currentStep).prevAll('.step').removeClass(isActive);
            $(currentStep).nextAll('.step').removeClass(isActive);
            $(currentStep).nextAll('.step').removeClass(isChecked);

            setStepIsActive(currentStep);
        };

        setStepIsActive = (step) => {
            $(step, $progressBar).addClass(isActive);
            $(step, $progressBar).removeClass(isChecked);
        };

        showProgressBar = () => {
            $progressBar.removeClass(isHidden);
        };

        init();
    }
};
