import debug from './debug.js';

const tpRtrgtGdpr = '-1';
const tpRtrgtFirstScript = document.querySelectorAll('script')[0];
let tpRtrgtIframe = 0;

function getConsent() {
    try {
        window.__ucCmp.getConsentDetails().then((r) => {
            for (const key in r.services) {
                if (r.services[key].name === 'TheReachGroup') {
                    if (r.services[key].consent.given) {
                        window.Rtr = true;
                        const tpRtrgtGdprConsent = '';

                        /* DO NOT change the javascript below */
                        const tpRtrgtRandom = Math.random() * 10 ^ 15;
                        let tpRtrgtUrl = 'http' + (('https:' === document.location.protocol) ? 's' : '') + '://hal9000.redintelligence.net/retarget?a=70932&version=1';
                        tpRtrgtUrl += '&gdpr=' + tpRtrgtGdpr;
                        tpRtrgtUrl += '&gdpr_consent=' + tpRtrgtGdprConsent;
                        tpRtrgtIframe = 'i' + tpRtrgtRandom;

                        window.tpRtrgtIframes = window.tpRtrgtIframes || [];
                        window.tpRtrgtIframes[tpRtrgtIframe] = document.createElement('iframe');
                        window.tpRtrgtIframes[tpRtrgtIframe].height = '1';
                        window.tpRtrgtIframes[tpRtrgtIframe].width = '1';
                        window.tpRtrgtIframes[tpRtrgtIframe].scrolling = 'no';
                        window.tpRtrgtIframes[tpRtrgtIframe].cssText = 'position:absolute;visibility:hidden;left:0px;top:0px;width:1px;height:1px;border:0';
                        window.tpRtrgtIframes[tpRtrgtIframe].class = 'reachgroup';

                        window.tpRtrgtIframes[tpRtrgtIframe].src = tpRtrgtUrl;

                        tpRtrgtFirstScript.parentNode.append(window.tpRtrgtIframes[tpRtrgtIframe]);
                    } else {
                        window.Rtr = false;
                        window.tpRtrgtIframes = window.tpRtrgtIframes || [];
                        window.tpRtrgtIframes = document.querySelectorAll('iframe.reachgroup');
                        if (window.tpRtrgtIframes.length > 0) {
                            window.tpRtrgtIframes.each((iframeElement) => {
                                tpRtrgtFirstScript.parentNode.remove(iframeElement);
                            });
                        }
                    }
                }
            }
        });
    } catch (error) {
        debug.log(error);
    }
}
export default () => {
    setTimeout(() => {
        getConsent();
        window.addEventListener('userCentricsUpdate', () => {
            getConsent();
        });
    }, 500);
};
