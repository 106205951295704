import $ from 'jquery/dist/jquery.slim';
import {BehaviorSubject} from 'rxjs';
import ScreenSizeDetector from 'screen-size-detector'; // https://github.com/devfakhzan/screen-size-detector

export let viewportSize = new BehaviorSubject('');
export let viewportSizeLgDown = new BehaviorSubject('');

export default () => {
    let init;
    let screenSizeDetector;

    init = () => {
        screenSizeDetector();
    };

    screenSizeDetector = () => {
        const setDataViewportSizeToHtml = () => {
            $('html').attr('data-viewport-size', viewportSize.getValue());
        };

        const defaultOptions = {
            onWidthChange: () => {
                // Update viewport size
                setDataViewportSizeToHtml();
            },
            widthDefinitions: {
                lg: {
                    inclusion: '[]',
                    max: 1199,
                    min: 992,
                    onEnter: () => {
                        // viewportSize = 'lg';
                        viewportSize.next('lg');
                    },
                },
                md: {
                    inclusion: '[]',
                    max: 991,
                    min: 768,
                    onEnter: () => {
                        // viewportSize = 'md';
                        viewportSize.next('md');
                        viewportSizeLgDown.next('lg-down');
                    },
                },
                sm: {
                    inclusion: '[]',
                    max: 767,
                    min: 576,
                    onEnter: () => {
                        // viewportSize = 'sm';
                        viewportSize.next('sm');
                        viewportSizeLgDown.next('lg-down');
                    },
                },
                xl: {
                    inclusion: '[]',
                    max: 1439,
                    min: 1200,
                    onEnter: () => {
                        // viewportSize = 'xl';
                        viewportSize.next('xl');
                    },
                },
                xs: {
                    inclusion: '[]',
                    max: 575,
                    min: 0,
                    onEnter: () => {
                        // viewportSize = 'xs';
                        viewportSize.next('xs');
                        viewportSizeLgDown.next('lg-down');
                    },
                },
                xxl: {
                    inclusion: '[]',
                    max: Infinity,
                    min: 1440,
                    onEnter: () => {
                        // viewportSize = 'xxl';
                        viewportSize.next('xxl');
                    },
                },
            },
        };

        new ScreenSizeDetector(defaultOptions);

        // Add initial viewport size
        setDataViewportSizeToHtml();
    };

    init();
};
